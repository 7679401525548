class _ModalBus {
    addModalCb;
    registerAddModalCallback(cb) {
        this.addModalCb = cb;
    }
    addModal(item, props, closeCallback) {
        this.addModalCb(item, props, closeCallback);
    }
    closeLastModalCb;
    registerCloseLastModal(cb) {
        this.closeLastModal = cb;
    }
    closeLastModal() {
        this.closeLastModalCb();
    }
    modals = [];
    setModalsArray(arr) {
        this.modals = arr;
    }
    getModals = () => {
        return this.modals;
    };
}
export const ModalBus = new _ModalBus();
