import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "attachmentList"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_file_dropper = _resolveComponent("file-dropper");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_file_dropper, {
            class: "pointer",
            modelValue: _ctx.filelist,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.filelist) = $event))
        }, {
            default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
        }, 8, ["modelValue"]),
        (_ctx.files.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (item, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                        class: "attachment",
                        key: i
                    }, [
                        _createElementVNode("button", {
                            type: "button",
                            class: "btn btn-link text-danger text-decoration-none",
                            onClick: ($event) => (_ctx.remove(i))
                        }, "X", 8, _hoisted_2),
                        _createTextVNode(" " + _toDisplayString(item.name), 1)
                    ]));
                }), 128))
            ]))
            : _createCommentVNode("", true)
    ]));
}
