import { CommonServices } from '@/services/CommonServices';
import { baseUrl } from '@/config';
class _OnOfficeServices {
    LoginFromOnOffice(userId, customerWebId, apiclaim) {
        return CommonServices.defaultGet(baseUrl + "api/OnOffice/LoginFromOnOffice", {
            userId,
            customerWebId,
            apiclaim
        });
    }
    GetSignature(message) {
        return CommonServices.defaultGet(baseUrl + "api/OnOffice/GetSignature", {
            message
        });
    }
    GetEstateFiles(customerWebId, apiClaim, estateId) {
        return CommonServices.defaultGet(baseUrl + "api/OnOffice/GetEstateFiles", {
            customerWebId,
            apiClaim,
            estateId
        });
    }
    GetEstateDetails(userId, customerWebId, apiClaim, estateId) {
        return CommonServices.defaultGet(baseUrl + "api/OnOffice/GetEstateDetails", {
            userId,
            customerWebId,
            apiClaim,
            estateId
        });
    }
    CreatePendingOrder(vm, onProgress) {
        return CommonServices.uploadFileToUrl(baseUrl + "api/OnOffice/CreatePendingOrder", vm, onProgress);
    }
}
export let OnOfficeServices = new _OnOfficeServices();
