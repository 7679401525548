import store from '@/store';
import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/',
        name: 'layout',
        component: () => import('@/views/layout.vue'),
        children: [
            {
                path: '/taskCreated',
                name: 'taskCreated',
                meta: { clearCache: true },
                component: () => import('@/views/taskCreated.vue'),
            },
            {
                path: '/orderConfirmed',
                name: 'orderConfirmed',
                meta: { clearCache: true },
                component: () => import('@/views/orderConfirmed.vue'),
            },
            {
                path: '/customers/:customerIdentifier/dash',
                name: 'customerDash',
                meta: { clearCache: true },
                // redirect: '/customers/:customerIdentifier/dash/workInProgress',
                component: () => import('@/views/customer/customerDash.vue'),
                children: [
                    {
                        path: '/customers/:customerIdentifier/dash/workInProgress',
                        name: 'customerDash_workInProgress',
                        component: () => import('@/views/customer/workInProgress.vue'),
                    },
                    {
                        path: '/customers/:customerIdentifier/dash/buyCredits',
                        name: 'customerDash_buyCredits',
                        component: () => import('@/views/customer/buyCredits.vue'),
                    },
                    {
                        path: '/customers/:customerIdentifier/dash/order',
                        name: 'customerDash_order',
                        component: () => import('@/views/customer/order.vue'),
                    },
                    {
                        path: '/customers/:customerIdentifier/dash/postOrder/:productIdentifier',
                        name: 'customerDash_postOrder',
                        component: () => import('@/views/customer/order.vue'),
                    },
                ]
            },
        ]
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes
});
export default router;
function hasQueryParams(route) {
    return !!Object.keys(route.query).length;
}
router.beforeEach((to, from, next) => {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
        next({
            name: to.name,
            params: to.params,
            query: from.query
        });
    }
    else {
        next();
    }
});
let clienteRoutes = () => [
    { text: 'Dashboard', path: '/customers/' + store.state.loginResponse.userIdentifier + '/dash/workInProgress', },
];
export let menuRoutes = {
    clienteRoutes
};
