import { createStore } from 'vuex';
import * as OM from '@/model';
import { StorageServices } from '@/services/StorageServices';
import { AppRole } from '@/const';
import { getUrlParameter } from '@/utils';
let storeState = {
    config: new OM.AppConfig(),
    loginResponse: StorageServices.getLoginResponse() || new OM.LoginResponse(),
    addBgTask: (() => { }),
    selectedLanguage: "",
    consts: {
        localizedValues: [],
        enabledLanguages: [],
    },
    isAdmin: () => {
        return storeState.loginResponse.role == AppRole.Admin;
    },
    isOffice: () => {
        return storeState.loginResponse.role == AppRole.Office;
    },
    isCollaboratore: () => {
        return storeState.loginResponse.role == AppRole.Collaboratore;
    },
    isClienteBig: () => {
        return storeState.loginResponse.role == AppRole.ClienteBig;
    },
    isCliente: () => {
        return storeState.loginResponse.role == AppRole.Cliente;
    },
    globalSpinner: 0,
    customer: null,
    nextTaskId: 0,
    bgTasks: [],
    getParameterApiToken: "",
    getParameterCacheId: "",
    getParameterApiClaim: "",
    getParameterUserId: "",
    getParameterCustomerWebId: "",
    getParameterEstateId: "",
    getParameterThankyou: "",
    getParameterTaskCreated: "",
    getParameterLang: "",
};
const GET_PARAMETER_APICLAIM = 'apiClaim';
const GET_PARAMETER_APITOKEN = 'apiToken';
const GET_PARAMETER_CACHE_ID = 'parameterCacheId';
const GET_PARAMETER_SIGNATURE = 'signature';
const GET_PARAMETER_USERID = 'userId';
const GET_PARAMETER_CUSTOMERWEBID = 'customerWebId';
const GET_PARAMETER_ESTATEID = 'estateId';
const GET_PARAMETER_THANKYOU = 'thankyou';
const GET_PARAMETER_TASKCREATED = 'taskcreated';
const GET_PARAMETER_LANG = 'lang';
storeState.getParameterApiToken = getUrlParameter(GET_PARAMETER_APITOKEN);
storeState.getParameterCacheId = getUrlParameter(GET_PARAMETER_CACHE_ID);
storeState.getParameterApiClaim = getUrlParameter(GET_PARAMETER_APICLAIM);
storeState.getParameterUserId = getUrlParameter(GET_PARAMETER_USERID);
storeState.getParameterCustomerWebId = getUrlParameter(GET_PARAMETER_CUSTOMERWEBID);
storeState.getParameterEstateId = getUrlParameter(GET_PARAMETER_ESTATEID);
storeState.getParameterThankyou = getUrlParameter(GET_PARAMETER_THANKYOU);
storeState.getParameterTaskCreated = getUrlParameter(GET_PARAMETER_TASKCREATED);
storeState.getParameterLang = getUrlParameter(GET_PARAMETER_LANG);
export default createStore({
    state: storeState
});
