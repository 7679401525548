import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import FTS from '@/filters';
import '@/css/lato.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/css/style.css';
import OpModal from '@/plugins/modalPlugin';
import * as DIR from '@/directives';
const app = createApp(App)
    .use(store)
    .use(router)
    .use(OpModal)
    .directive("date", DIR.date)
    .directive("number", {
    beforeMount: DIR.number.beforeMount,
    updated: DIR.number.updated
})
    .directive("required", {
    beforeMount: DIR.required.beforeMount,
    updated: DIR.required.updated
})
    .directive("scadenza", DIR.scadenza)
    .directive("telephone", DIR.telephone)
    .directive("label", DIR.label)
    .directive("localizedPlaceholder", DIR.localizedPlaceholder);
app.config.globalProperties.$filters = FTS;
const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, component.default || component);
});
app.mount('#app');
import 'swiper/css/swiper.min.css';
//toastr
import 'toastr/build/toastr.min.css';
import toastr from 'toastr';
toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 4000,
    extendedTimeOut: 1000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};
window.toastr = toastr;
