import store from '@/store';
import { StatoTask } from './const';
import { LocalizationServices } from './services/LocalizationServices';
function directiveBindFn(el, binding, vnode, regExpr, classSuffix) {
    if (binding.value != undefined && !binding.value)
        return;
    el.classList.add('v-untouched');
    let vmodelIndex = vnode.data.directives.findIndex(x => x.name == 'model');
    let vmodelValue = vnode.data.directives[vmodelIndex].value;
    //if(vmodelValue == undefined || vmodelValue == null || vmodelValue == '')
    //    return;
    if (checkFunction(vmodelValue, regExpr)) {
        el.classList.add('v-valid-' + classSuffix);
    }
    else {
        el.classList.add('v-invalid-' + classSuffix);
    }
}
function directiveUpdateFn(el, binding, vnode, regExpr, classSuffix) {
    let vmodelIndex = vnode.data.directives.findIndex(x => x.name == 'model');
    let vmodelDirective = vnode.data.directives[vmodelIndex];
    if (vmodelDirective.oldValue === undefined || vmodelDirective.value === vmodelDirective.oldValue)
        return;
    el.classList.remove('v-untouched');
    el.classList.remove('v-invalid-' + classSuffix);
    el.classList.remove('v-valid-' + classSuffix);
    if (binding.value != undefined && !binding.value) {
        return;
    }
    let vmodelValue = vmodelDirective.value;
    if (checkFunction(vmodelValue, regExpr)) {
        el.classList.add('v-valid-' + classSuffix);
    }
    else {
        el.classList.add('v-invalid-' + classSuffix);
    }
}
function checkFunction(val, regExp) {
    if (val === null || val === undefined)
        return false;
    val = val.toString().trim();
    if (!regExp) {
        return !!val;
    }
    else {
        if (regExp.test(val)) {
            return true;
        }
        else {
            return false;
        }
    }
}
export let required = {
    beforeMount: (el, binding, vnode) => {
        directiveBindFn(el, binding, vnode, null, 'required');
    },
    updated: (el, binding, vnode) => {
        directiveUpdateFn(el, binding, vnode, null, 'required');
    }
};
export let number = {
    beforeMount: (el, binding, vnode) => {
        let regExp = new RegExp(/^(-?\d+\.\d+)$|^(-?\d+)$/gm);
        directiveBindFn(el, binding, vnode, regExp, 'number');
    },
    updated: (el, binding, vnode) => {
        let regExp = new RegExp(/^(-?\d+\.\d+)$|^(-?\d+)$/gm);
        directiveUpdateFn(el, binding, vnode, regExp, 'number');
    }
};
let telephoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
export function telephone(el, binding, vnode) {
    el.classList.remove('v-invalid-telephone');
    el.classList.remove('v-valid-telephone');
    let vmodelIndex = vnode.data.directives.findIndex(x => x.name == 'model');
    let vmodelValue = vnode.data.directives[vmodelIndex].value;
    if (el.tagName.toLowerCase() != 'input' && el.tagName.toLowerCase() != 'select') {
        console.error('v-telephone directive is not on tag input or select, but on tag ' + el.tagName + ". Se è una textarea aggiusta la cosa");
        return;
    }
    let found = vmodelValue.match(telephoneRegex);
    if (!found) {
        el.classList.add('v-invalid-telephone');
    }
}
export function date(el, binding, next, prev) {
    if (el.tagName.toLowerCase() != 'input') {
        console.error('v-date directive must be used on input tags. use type="date"');
        return;
    }
    if (el.getAttribute('type') != 'date') {
        console.error('v-date directive must be used on input with type="date"');
        return;
    }
    let modelDirectiveIndex = next.data.directives.findIndex(x => x.name == 'model');
    if (modelDirectiveIndex == -1) {
        console.error('v-date directive requires v-model on element');
        return;
    }
    let val = next.data.directives[modelDirectiveIndex].value;
    if (val) {
        el.value = validateDate(val);
    }
}
function validateDate(inputDate) {
    let date = new Date(inputDate);
    if (isValidDate(date)) {
        let years = date.getFullYear().toString();
        while (years.length < 4) {
            years = "0" + years;
        }
        let months = (date.getMonth() + 1).toString();
        if (months.length < 2)
            months = "0" + months;
        let days = date.getDate().toString();
        if (days.length < 2)
            days = "0" + days;
        return years + "-" + months + "-" + days;
    }
}
function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}
export function label(el, binding, vnode) {
    el.classList.remove("disponibile");
    el.classList.remove("inLavorazione");
    el.classList.remove("concluso");
    el.classList.remove("assegnato");
    el.classList.remove("inApprovazione");
    el.classList.remove("standby");
    el.classList.remove("inModifica");
    switch (binding.value) {
        case StatoTask.Assegnato:
            el.classList.add("assegnato");
            break;
        case StatoTask.Concluso:
            el.classList.add("concluso");
            break;
        case StatoTask.Disponibile:
            el.classList.add("disponibile");
            break;
        case StatoTask.InApprovazione:
            el.classList.add("inApprovazione");
            break;
        case StatoTask.InLavorazione:
            el.classList.add("inLavorazione");
            break;
        case StatoTask.InModifica:
            el.classList.add("inModifica");
            break;
        case StatoTask.Standby:
            el.classList.add("standby");
            break;
    }
}
export function scadenza(el, binding, vnode) {
    el.classList.remove("text-red");
    el.classList.remove("text-orange");
    if (!binding.value)
        return;
    let date = new Date(binding.value).getTime();
    if (date - Date.now() < 24 * 60 * 60 * 1000) {
        el.classList.add("text-red");
    }
    else if (date - Date.now() < 2 * 24 * 60 * 60 * 1000) {
        el.classList.add("text-orange");
    }
}
function placeholderCallback(el, binding) {
    var key = binding.value["key"];
    var value = binding.value["value"];
    if (!key || !value) {
        console.error('v-localizedPlaceholder need key and value');
        return;
    }
    var prevText = binding.value["prevText"];
    var afterText = binding.value["afterText"];
    var text = LocalizationServices.GetLocalizedValue(key, value, afterText, prevText);
    el.placeholder = text;
}
export let localizedPlaceholder = {
    beforeMount: (el, binding, vnode) => {
        placeholderCallback(el, binding);
        el.unwatcher = store.watch(state => {
            return state.selectedLanguage;
        }, () => {
            placeholderCallback(el, binding);
        });
    },
    updated: (el, binding, vnode) => {
        placeholderCallback(el, binding);
    },
    unmounted(el, binding, vnode, prevVnode) {
        el.unwatcher();
    }
};
