import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6e54a062"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onMousedown", "onMouseup", "onTouchstart", "onTouchend"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["__modalsContainer", { '__show': _ctx.items.length > 0 }])
    }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
                class: "__modalBg",
                key: i,
                ref_for: true,
                ref: '__modalBg' + i,
                onMousedown: ($event) => (_ctx.onDownBg($event, i)),
                onMouseup: ($event) => (_ctx.onUpBg($event, i)),
                onTouchstart: ($event) => (_ctx.onDownBg($event, i)),
                onTouchend: ($event) => (_ctx.onUpBg($event, i))
            }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(item.modalComponent), _mergeProps(item.props, {
                    onClose: ($event) => (_ctx.closeModal(i))
                }), null, 16, ["onClose"]))
            ], 40, _hoisted_1));
        }), 128))
    ], 2));
}
