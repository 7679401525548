function inIframe() {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
}
class _StorageServices {
    readFromLocalStorage(lsName) {
        if (inIframe())
            return null;
        try {
            let data = window.localStorage.getItem(lsName);
            if (data) {
                return JSON.parse(data);
            }
            else {
                return null;
            }
        }
        catch (ex) {
            window.localStorage.removeItem(lsName);
        }
    }
    setToLocalStorage(lsName, value) {
        if (inIframe())
            return;
        window.localStorage.setItem(lsName, JSON.stringify(value));
    }
    getAuthToken() {
        return this.readFromLocalStorage('AuthToken');
    }
    setAuthToken(AuthToken) {
        this.setToLocalStorage('AuthToken', AuthToken);
    }
    removeAuthToken() {
        window.localStorage.removeItem('Authtoken');
    }
    getLoginResponse() {
        return this.readFromLocalStorage('loginResponse');
    }
    setLoginResponse(loginResponse) {
        this.setToLocalStorage('loginResponse', loginResponse);
    }
    getCustomerTaskFilter() {
        return this.readFromLocalStorage('customerTaskFilter');
    }
    setCustomerTaskFilter(filter) {
        this.setToLocalStorage('customerTaskFilter', filter);
    }
    getCustomerTaskDaysFilter() {
        return this.readFromLocalStorage('customerTaskDaysFilter');
    }
    setCustomerTaskDaysFilter(daysFilter) {
        this.setToLocalStorage('customerTaskDaysFilter', daysFilter);
    }
    SetLanguage(language) {
        this.setToLocalStorage('locale', language);
    }
    GetLanguage() {
        let data = this.readFromLocalStorage('locale');
        if (!data) {
            return '';
        }
        else {
            return data;
        }
    }
}
export let StorageServices = new _StorageServices();
