import * as OM from '@/model';
export class BgTask {
    taskId;
    taskName;
    action;
    progress;
}
export class LoginVm {
    email;
    password;
}
export class RegisterCustomerVm {
    name;
    surname;
    agenzia;
    telefono;
    email;
    password;
}
export class PasswordResetVm {
    email;
    token;
    newPassword;
}
export class UploadAttachmentVm {
    itemIdentifier;
    file;
}
export class TaskVm {
    identifier;
    product;
    creditPrice;
    title;
    startDate;
    dueDate;
    endDate;
    lastState;
    deliveryAttachmentsCount;
    showDeliveryAttachments;
}
export class AssignTaskVm {
    taskIdentifier;
    userIdentifier;
}
export class GetTaskTableFilterVm {
    showConcluded;
    officeIdentifier;
    userIdentifier;
    fromDate;
    toDate;
    constructor() {
        this.showConcluded = false;
        this.officeIdentifier = null;
        this.userIdentifier = null;
        this.fromDate = null;
        this.toDate = null;
    }
}
export class TaskTableResponseVm {
    totalTasks;
    totalCrediti;
    averageCompenso;
    totalCompenso;
    data;
    constructor() {
        this.totalCompenso = null;
        this.totalTasks = null;
        this.averageCompenso = null;
        this.data = [];
    }
}
export class TaskTableVm {
    identifier;
    product;
    title;
    customer;
    userAssigned;
    startDate;
    dueDate;
    lastState;
    compenso;
    priorita;
}
export class ProductVm {
    identifier;
    name;
    productType;
}
export class CreateCustomerChildVm {
    parentIdentifier;
    name;
    surname;
    email;
    password;
}
export class TaskTrelloVm {
    user;
    tasks;
}
export class BuyOfferVm {
    token;
    shopOfferIdentifier;
    optionName;
    customerIdentifier;
}
export class BuyPostOrderVm {
    token;
    productIdentifier;
    upsellProductName;
    customerIdentifier;
}
export class SendEmailVm {
    email;
    fromMail;
    subject;
    htmlContent;
    mailTemplateName;
}
export class CustomerServiceTableVm {
    identifier;
    createdOn;
    isCliente;
    name;
    email;
    source;
    credits;
    lastTaskOn;
    lastLoginOn;
    lastContact;
}
export class NewClientiStatVm {
    clienti;
    lead;
}
export class SpesaCollaboratoriVm {
    name;
    amount;
}
export class GetCustomerForTrelloFilterVm {
    name;
    officeIdentifier;
    showClienti;
    showNonClienti;
    stelleFilter;
    clusterFilter;
    state;
    sortByScadenza;
    constructor() {
        this.name = "";
        this.officeIdentifier = "";
        this.showClienti = false;
        this.showNonClienti = false;
        this.stelleFilter = [];
        this.clusterFilter = [];
        this.state = null;
        this.sortByScadenza = -1;
    }
}
export class RiepilogoGiornalieroVm {
    identifier;
    date;
    createdBy;
}
export class StoricoClienteVm {
    transactions;
    tasks;
}
export class CreditTransactionVm {
    date;
    price;
    credits;
    note;
    customer;
    task;
}
export class TaskWithModificheVm {
    taskName;
    taskDate;
    modificheHistory;
    creditPrice;
    product;
}
export class SaveUsersOrderVm {
    user;
    order;
}
export class ExportTaskFilterVm {
    clienteParentIdentifier;
    customerIdentifier;
    dateFrom;
    dateTo;
    constructor() {
        this.clienteParentIdentifier = null;
        this.customerIdentifier = null;
        this.dateFrom = null;
        this.dateTo = null;
    }
}
export class StripeCardDetailVm {
    cardId;
    brand;
    country;
    currency;
    expMonth;
    expYear;
    dynamicLast4;
    last4;
    holder;
}
export class StripeSaveCardVm {
    customerIdentifier;
    paymentMethodId;
}
export class MenuRoute {
    text;
    path;
}
export class Attachment extends OM.Attachment {
    fileToUpload;
    toRemove;
}
export class ShopOfferOption extends OM.ShopOfferOption {
    selected;
}
export class MakeOrderVm {
    customerIdentifier;
    productIdentifier;
    titolo;
    scalimetro;
    moltiplicatoreMetratura;
    note;
    source;
    creditsBought;
    onOfficeEstateId;
    files;
    onOfficeFiles;
    constructor() {
        this.customerIdentifier = "";
        this.productIdentifier = "";
        this.titolo = "";
        this.scalimetro = "";
        this.moltiplicatoreMetratura = null;
        this.note = "";
        this.creditsBought = 0;
        this.onOfficeEstateId = "";
        this.source = "";
        this.files = [];
        this.onOfficeFiles = [];
    }
}
export class PaymentJson {
    callbackurl;
    parametercacheid;
    products;
    timestamp;
    totalprice;
    signature;
}
export class PaymentProduct {
    circleofusers = "customer";
    name;
    price;
    quantity;
}
export class OnOfficeFileDetail {
    filename;
    b64Content;
}
export class OnOfficeEstateDetail {
    street;
    number;
    postalCode;
    region;
    nation;
}
