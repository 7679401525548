import moment from 'moment';
export default {
    date(value, _format = "DD/MM/YYYY", _showTime = false) {
        if (value) {
            let format = _format;
            if (_showTime)
                format += ' HH:mm:ss';
            return moment(value).locale('it').format(format);
        }
    },
    int(value) {
        if (value || value == 0) {
            return Math.round(value);
        }
    },
    currency(value, decimals = 2) {
        if (!decimals && decimals != 0)
            decimals = 2;
        if (value || value == 0) {
            let val = (value / 1).toFixed(decimals).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    },
    perc(value, decimals) {
        if (!decimals)
            decimals = 0;
        if (value || value == 0) {
            return (value * 100).toFixed(decimals);
        }
    },
    daysPeriod(value) {
        if (!value)
            return value;
        if (value < 365)
            return value;
        else if (value < 1080)
            return value;
        else
            return parseFloat((value / 365).toFixed(1));
    },
};
