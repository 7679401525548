import { CommonServices } from '@/services/CommonServices';
import { baseUrl } from '@/config';
class _TaskServices {
    UploadRequestAttachment(vm, onProgress) {
        return CommonServices.uploadFileToUrl(baseUrl + "api/Task/UploadRequestAttachment", vm, onProgress);
    }
    UploadDeliveryAttachment(vm, onProgress) {
        return CommonServices.uploadFileToUrl(baseUrl + "api/Task/UploadDeliveryAttachment", vm, onProgress);
    }
    MakeOrder(vm, onProgress) {
        return CommonServices.uploadFileToUrl(baseUrl + "api/Task/MakeOrder", vm, onProgress);
    }
    DownloadFromGestim(url) {
        let encodedUrl = encodeURI(url);
        window.open(baseUrl + "api/Task/DownloadFromGestim?url=" + encodedUrl, "_blank");
    }
    DownloadAttachment(taskIdentifier, fileIdentifier) {
        let encodedFileId = encodeURIComponent(fileIdentifier);
        window.open(baseUrl + "api/Task/DownloadAttachment?taskIdentifier=" + taskIdentifier + "&fileIdentifier=" + encodedFileId, "_blank");
    }
    DownloadReport(fileId, name) {
        window.open(baseUrl + "api/Task/DownloadReport?fileId=" + fileId + "&name=" + name, "_blank");
    }
    DownloadZip(fileId, name) {
        window.open(baseUrl + "api/Task/DownloadZip?fileId=" + fileId + "&name=" + name, "_blank");
    }
}
export let TaskServices = new _TaskServices();
