import { openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7856a178"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = { key: 1 };
const _hoisted_3 = { class: "d-flex justify-content-around" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: "opModal",
        style: _normalizeStyle('width:' + _ctx.width + '!important;')
    }, [
        (_ctx.isHtml)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                innerHTML: _ctx.text
            }, null, 8, _hoisted_1))
            : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.text), 1)),
        _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
                type: "button",
                class: "btn btn-green",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.ok && _ctx.ok(...args)))
            }, "OK")
        ])
    ], 4));
}
