export function parseTemplate(input, data) {
    let currentString = input;
    let currentPosition = 0;
    let ris = "";
    let leftBracketPosition = input.indexOf("{{");
    let rightBracketPosition = input.indexOf("}}");
    while (leftBracketPosition > -1 && rightBracketPosition > -1) {
        ris += currentString.substring(currentPosition, leftBracketPosition);
        let parola = currentString.substring(leftBracketPosition + 2, rightBracketPosition);
        let link = parola;
        let interpolatedValue = '' + data[parola];
        ris += interpolatedValue;
        currentString = currentString.substring(rightBracketPosition + 2);
        leftBracketPosition = currentString.indexOf("{{");
        rightBracketPosition = currentString.indexOf("}}");
    }
    ris += currentString.substring(rightBracketPosition);
    return ris;
}
export function getUrlParameter(sParam) {
    var url = window.location.search.substring(1), urlvariables = url.split('&'), parameterName, i;
    for (i = 0; i < urlvariables.length; i++) {
        parameterName = urlvariables[i].split('=');
        if (parameterName[0].toLowerCase() === sParam.toLowerCase()) {
            return parameterName[1] === undefined ? true : decodeURIComponent(parameterName[1]);
        }
    }
}
export function checkSignature(url) {
    let promise = new Promise((resolve, reject) => {
        let questionPosition = url.indexOf("?");
        let urlWithoutParameters = url.substring(0, questionPosition);
        let parameters = url.substring(questionPosition);
        let signaturePosition = parameters.indexOf('&signature=');
        let signature = parameters.substring('&signature='.length + signaturePosition);
        let parametersWithoutSignature = parameters.substring(0, signaturePosition);
        let message = urlWithoutParameters + parametersWithoutSignature;
        // fetch('http://localhost:5000/api/OnOffice/CheckSignature?message=' + message + '&signature=' + signature)
        fetch('https://api.totalrender.it/api/OnOffice/CheckSignature?message=' + message + '&signature=' + signature)
            .then(response => response.json())
            .then(data => {
            resolve(data);
        })
            .catch(err => reject(err));
    });
    return promise;
}
export function activateMarketplace(userSecretKey, apiToken, apiClaim, parameterCacheId, userId) {
    let promise = new Promise((resolve, reject) => {
        // fetch('http://localhost:5000/api/OnOffice/ActivateMarketplace?userSecretKey=' + userSecretKey + '&apiToken=' + apiToken + '&apiClaim=' + apiClaim + '&parameterCacheId=' + parameterCacheId + '&userId=' + userId)
        fetch('https://api.totalrender.it/api/OnOffice/ActivateMarketplace?userSecretKey=' + userSecretKey + '&apiToken=' + apiToken + '&apiClaim=' + apiClaim + '&parameterCacheId=' + parameterCacheId + '&userId=' + userId)
            .then(response => {
            if (response && typeof response.json == 'function') {
                return response.json();
            }
            else {
                return response;
            }
        })
            .then(data => {
            resolve(data);
        })
            .catch(err => reject(err));
    });
    return promise;
}
export function getMessageToSign(obj) {
    var ris = "";
    for (let p in obj) {
        if (p == 'products') {
            let prodValue = obj[p][0];
            for (let prop in prodValue) {
                ris += encodeURIComponent('products[0][') + prop + encodeURIComponent(']');
                ris += "=";
                ris += encodeURIComponent(prodValue[prop].toString());
                ris += "&";
            }
        }
        else {
            ris += p;
            ris += "=";
            ris += encodeURIComponent(obj[p].toString());
            ris += "&";
        }
    }
    if (ris[ris.length - 1] == "&") {
        ris = ris.substring(0, ris.length - 1);
    }
    return ris;
}
