import Axios from 'axios';
import { StorageServices } from './StorageServices';
class _CommonServices {
    showSpinnerCb = () => { };
    hideSpinnerCb = () => { };
    unauthorizedCb = () => { };
    defaultGet(url, params, _showSpinner = true) {
        let req = {
            params
        };
        if (_showSpinner)
            this.showSpinnerCb();
        let prom = new Promise((resolve, reject) => {
            Axios.get(url, req)
                .then(x => {
                resolve(x.data);
            }).catch(err => {
                reject(err);
            })
                .finally(() => {
                if (_showSpinner)
                    this.hideSpinnerCb();
            });
        });
        return prom;
    }
    defaultPost(url, data, config, _showSpinner = true) {
        if (_showSpinner)
            this.showSpinnerCb();
        let prom = new Promise((resolve, reject) => {
            Axios.post(url, data, config).then(x => {
                resolve(x.data);
            }).catch(err => {
                reject(err);
            })
                .finally(() => {
                if (_showSpinner)
                    this.hideSpinnerCb();
            });
        });
        return prom;
    }
    // private createFormData(params){
    //     var ris = new FormData();
    //     if (params) {
    //         for (var key in params) {
    //             if(isArray(params[key])){
    //                 params[key].forEach(item => {
    //                     ris.append(key, item);
    //                 })
    //             } else if(typeof params[key] == 'object'){
    //                 ris.append(key, params[key]);
    //             } else {
    //                 ris.append(key, params[key]);
    //             }
    //         }
    //     }
    // }
    convertModelToFormData(data = {}, form = null, namespace = '') {
        let files = {};
        let model = {};
        for (let propertyName in data) {
            if (data.hasOwnProperty(propertyName) && data[propertyName] instanceof File) {
                files[propertyName] = data[propertyName];
            }
            else
                model[propertyName] = data[propertyName];
        }
        let formData = form || new FormData();
        for (let propertyName in model) {
            if (!model.hasOwnProperty(propertyName) || !model[propertyName])
                continue;
            let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
            if (model[propertyName] instanceof Date) {
                formData.append(formKey, model[propertyName].toISOString());
            }
            else if (model[propertyName] instanceof File) {
                formData.append(formKey, model[propertyName]);
            }
            else if (model[propertyName] instanceof Array) {
                model[propertyName].forEach(async (element, index) => {
                    let tempFormKey = `${formKey}[${index}]`;
                    if (element instanceof File) {
                        tempFormKey = tempFormKey.substring(0, tempFormKey.length - 3);
                        formData.append(tempFormKey, element);
                    }
                    else if (typeof element === 'object') {
                        this.convertModelToFormData(element, formData, tempFormKey);
                    }
                    else {
                        formData.append(tempFormKey, element.toString());
                    }
                });
            }
            else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
                this.convertModelToFormData(model[propertyName], formData, formKey);
            }
            else {
                formData.append(formKey, model[propertyName].toString());
            }
        }
        for (let propertyName in files) {
            if (files.hasOwnProperty(propertyName)) {
                formData.append(propertyName, files[propertyName]);
            }
        }
        return formData;
    }
    uploadFileToUrl(url, params, onUploadProgress, _showSpinner = true) {
        var data = this.convertModelToFormData(params);
        var config = {
            onUploadProgress: function (ev) {
                if (typeof onUploadProgress == 'function')
                    onUploadProgress((100 * ev.loaded) / ev.total);
            }
        };
        if (_showSpinner)
            this.showSpinnerCb();
        let prom = new Promise((resolve, reject) => {
            Axios.post(url, data, config).then(x => {
                resolve(x.data);
            })
                .catch(err => {
                reject(err);
            })
                .finally(() => {
                if (_showSpinner)
                    this.hideSpinnerCb();
            });
        });
        return prom;
    }
    setAuthToken(token) {
        Axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        StorageServices.setAuthToken(token);
    }
    destroyToken() {
        Axios.defaults.headers.common['Authorization'] = "";
        StorageServices.removeAuthToken();
    }
    constructor() {
        let token = StorageServices.getAuthToken();
        if (token) {
            this.setAuthToken(token);
        }
        Axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (!error)
                return Promise.reject();
            if (!error.response)
                return Promise.reject(error);
            if (error.response.status == 401) {
                this.unauthorizedCb();
            }
            else if (error.response.status[0] == 5) {
                console.log("500");
                console.log(error);
            }
            else {
                if (error.response.data)
                    return Promise.reject(error.response.data);
                else
                    return Promise.reject(error);
            }
        });
    }
}
export let CommonServices = new _CommonServices();
