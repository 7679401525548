import store from '@/store';
import * as OM from '@/model';
import { StorageServices } from '@/services/StorageServices';
import { LocalizationClient } from './Services';
class _LocalizationServices {
    constructor() {
        // store.state.selectedLanguage = StorageServices.GetLanguage();
        // if (!store.state.selectedLanguage) {
        //     var keys = Object.keys(store.state.consts.enabledLanguages);
        //     store.state.selectedLanguage = store.state.consts.enabledLanguages[keys[0]];
        // }
    }
    SetLanguage(language) {
        store.state.selectedLanguage = language;
        StorageServices.SetLanguage(language);
    }
    GetLanguage() {
        return store.state.selectedLanguage;
    }
    GetLocalizedValue(key, value, afterText = '', prevText = '') {
        var existing = store.state.consts.localizedValues.find(x => x.key == key);
        let result;
        if (existing) {
            var selectedCulture = store.state.selectedLanguage;
            result = existing.values[selectedCulture];
        }
        else {
            var newValue = new OM.LocalizedEntity();
            newValue.key = key;
            newValue.identifier = "";
            newValue.key = key;
            store.state.consts.enabledLanguages.forEach(x => {
                newValue.values[x] = value;
            });
            LocalizationClient.save(newValue, false)
                .then(x => {
                store.state.consts.localizedValues.push(newValue);
            });
            result = value;
        }
        return prevText + result + afterText;
    }
    getTextFromValues(value, customLang = "") {
        var lang = store.state.selectedLanguage;
        if (customLang)
            lang = customLang;
        var keys = Object.keys(value);
        if (keys.indexOf(lang) == -1)
            return "";
        return value[lang];
    }
    getTextFromLocalizedValue(model, customLang = "") {
        var values = model.values;
        if (customLang)
            return this.getTextFromValues(values, customLang);
        return this.getTextFromValues(values);
    }
}
export let LocalizationServices = new _LocalizationServices();
