import { withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1f2ac524"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "__editableRoot" };
const _hoisted_2 = [".value"];
const _hoisted_3 = ["type"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.popupOpen)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "__editablePopup",
                ref: "editablePopup",
                onMousedown: _cache[8] || (_cache[8] = ($event) => (_ctx.catchClick = true))
            }, [
                (_ctx.type == 'textarea')
                    ? (_openBlock(), _createElementBlock("textarea", {
                        key: 0,
                        ref: "input",
                        onInput: _cache[0] || (_cache[0] = ev => _ctx.localValue = ev.target.value),
                        ".value": _ctx.localValue,
                        rows: "4",
                        onKeypress: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
                        //@ts-ignore
                        (...args) => (_ctx.ok && _ctx.ok(...args)), ["exact"]), ["enter"])),
                        onKeydown: _cache[2] || (_cache[2] = _withKeys(
                        //@ts-ignore
                        (...args) => (_ctx.cancel && _ctx.cancel(...args)), ["escape"]))
                    }, null, 40, _hoisted_2))
                    : _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 1,
                        ref: "input",
                        type: _ctx.type,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.localValue) = $event)),
                        onKeydown: [
                            _cache[4] || (_cache[4] = _withKeys(
                            //@ts-ignore
                            (...args) => (_ctx.ok && _ctx.ok(...args)), ["enter"])),
                            _cache[5] || (_cache[5] = _withKeys(
                            //@ts-ignore
                            (...args) => (_ctx.cancel && _ctx.cancel(...args)), ["escape"]))
                        ]
                    }, null, 40, _hoisted_3)), [
                        [_vModelDynamic, _ctx.localValue]
                    ]),
                _createElementVNode("button", {
                    type: "button",
                    onClick: _cache[6] || (_cache[6] =
                        //@ts-ignore
                        (...args) => (_ctx.ok && _ctx.ok(...args)))
                }, "OK"),
                _createElementVNode("button", {
                    type: "button",
                    onClick: _cache[7] || (_cache[7] =
                        //@ts-ignore
                        (...args) => (_ctx.cancel && _ctx.cancel(...args)))
                }, "X")
            ], 544))
            : _createCommentVNode("", true),
        _createElementVNode("div", {
            class: "__editableValue",
            ref: "editableValue",
            onClick: _cache[9] || (_cache[9] =
                //@ts-ignore
                (...args) => (_ctx.openPopup && _ctx.openPopup(...args)))
        }, [
            (_ctx.value != '' && _ctx.value != null && _ctx.value != undefined)
                ? _renderSlot(_ctx.$slots, "default", {
                    key: 0,
                    value: _ctx.value
                }, undefined, true)
                : _renderSlot(_ctx.$slots, "empty", { key: 1 }, undefined, true)
        ], 512)
    ]));
}
