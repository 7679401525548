import ModalsContainer from './modalsContainer.vue';
import { ModalBus } from './modalBus';
export default {
    install(app, options = {}) {
        if (this.installed) {
            return;
        }
        app.component('ModalsContainer', ModalsContainer);
        this.installed = true;
        app.config.globalProperties.$opModal = {
            modals: ModalBus.getModals,
            show(modal, props, closeCallback = null) {
                ModalBus.addModal(modal, props, closeCallback);
            },
            closeLast() {
                ModalBus.closeLastModal();
            }
        };
    }
};
